.hero {

  background-image: url("../../../../src/assets/Banner/banner-home.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}
.hero .container {
  padding-top: 6%;
}
.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}

.advance-filter {
  grid-column: span 2;
}

.line {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin: 1px 0;
}
form {
  /* background-color: #fff; */
  border-radius: 10px;
  /* margin-top: 130px; */
  /* padding: 0 20px; */
}
form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  /* margin-top: 5px; */
  border-radius: 5px;
}
form span {
  font-size: 14px;
  color: grey;
}
input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}
