  .font-secondary {
         font-family: "Dongle", sans-serif;
  /* font-weight: 400;
  font-style: normal; */
    }

    .title-font {
        font-family: "Dongle", sans-serif;
        font-weight: 400;
        font-style: normal;
        /* Size 32px */
        font-size: 32px;
    }